import cx from "clsx";
import { retrieveAxios } from "api-services/ApiCommon";
import {
    AgreementContext,
    useAgreementContext,
} from "apps/legal-ide/App/components/WizardUi/AgreementContext/AgreementContext";
import { autoDocService } from "apps/legal-ide/App/components/WizardUi/auto-contracts";
import { RichEditor } from "apps/legal-ide/App/Editor/Editor";
import { useEditor } from "apps/legal-ide/App/Editor/hooks";
import { Button, Card, Renderer, SvgIconPencilSign, SvgIconSave } from "components";
import { isProd } from "components/utils/EnvResolver";
import ShopifyBag from "img/shopify_bag.png";
import DudaIcon from "img/duda_icon.png";
import { useMedia } from "hooks";
import mixpanel from "mixpanel-browser";
import React, { MutableRefObject, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ReactTooltip from "react-tooltip";
import { Actions, useAppSelector } from "store";
import { DocsApi } from "../../../api-services/DocsApi";
import { ShopifyApi } from "../../../api-services/ShopifyApi";
import { callDialog, setIsDocPreviewOpen, setShowExplanations } from "../../../store/mainActions";
import { DialogType, MainStore } from "../../../store/mainTypes";
import { documentToName } from "../../ContractChooser";
import { SHOPIFY_SUPPORTED_DOCUMENTS } from "consts";
import "./Contract.scss";
import Modal, { ExitButton } from "components/Modal";

export function DocPreviewContent(props: { sign: boolean; completed?: boolean }) {
    const agreementContext = useContext(AgreementContext);
    let activeDraft = agreementContext?.getActiveDraft();
    const ref = React.createRef<HTMLDivElement>();

    const dispatch = useDispatch();
    const smallScreen = useMedia({ max: "lg" });

    useEffect(() => {
        return () => {
            if (smallScreen) {
                dispatch(setIsDocPreviewOpen(false));
            }
        };
    }, []);

    useEffect(() => {
        let listener = () => {
            ReactTooltip.hide();
        };
        ref.current?.addEventListener("scroll", listener);

        return () => {
            ref.current?.removeEventListener("scroll", listener);
        };
    }, [ref]);

    const htmlRef = React.createRef<HTMLDivElement>();
    const validSignCanvases = useSelector(
        (state: { mainReducer: MainStore }) => state.mainReducer.signCanvases
    )?.filter((signCanvas) => !!signCanvas);

    const firstSignCanvas = validSignCanvases ? validSignCanvases[0] : null;

    if (!activeDraft) {
        return null;
    }

    return (
        <Card
            id="DocPreviewContent"
            onMouseDown={(e) => {
                e.preventDefault(); // prevent copy doc
                return false;
            }}
            onMouseOver={(e) => {
                e.preventDefault(); // prevent copy doc
                return false;
            }}
            className="flex flex-col w-full max-w-5xl mx-auto"
        >
            <div ref={ref} className=" overflow-visible w-full flex justify-center">
                <div className="h-full w-full relative" ref={htmlRef}>
                    {props.sign && <SignButton firstSignCanvas={firstSignCanvas} />}
                    <PreviewContractWrapper />
                </div>
            </div>
        </Card>
    );
}

function SignButton({ firstSignCanvas }: { firstSignCanvas: MutableRefObject<any> | null }) {
    if (!(firstSignCanvas && firstSignCanvas.current && firstSignCanvas.current.getCanvas())) {
        return null;
    }

    return (
        <button
            className="absolute top-0 right-8 md:right-0 lg:top-8 lg:right-8 grid grid-cols-3 cursor-pointer rounded-lg bg-secondary py-2 px-3 hover:bg-secondaryHover z-10 gap-0.5"
            onClick={() => {
                firstSignCanvas?.current.getCanvas().parentElement.parentElement.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }}
        >
            <SvgIconPencilSign />
            <p className="text-base text-darkIndigo">Sign</p>
            <div />
        </button>
    );
}

export function PreviewContract(props: { contract: JSX.Element }) {
    return <div className="Contract flex flex-col w-full h-full">{props.contract}</div>;
}

export function HeadlessSaveButtons() {
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const htmlRef = React.createRef<HTMLDivElement>();

    const agreementContext = useAgreementContext();
    const activeDraft = agreementContext?.getActiveDraft();

    if (!activeDraft) {
        return null;
    }

    const documentKind = activeDraft.legalUnit?.kind!;
    const showShopify = dbUser?.isShopifyUser && SHOPIFY_SUPPORTED_DOCUMENTS.includes(documentKind);
    const showDuda = dbUser?.isDudaUser;
    return (
        <div className="flex gap-2 mx-auto w-full">
            <HeadlessDocPreview htmlRef={htmlRef} />
            <div
                className={cx(
                    "w-full grid gap-2",
                    showShopify || showDuda ? "grid-cols-3" : "grid-cols-2"
                )}
            >
                {dbUser?.isShopifyUser && SHOPIFY_SUPPORTED_DOCUMENTS.includes(documentKind) && (
                    <ShopifyButton htmlRef={htmlRef} />
                )}
                {dbUser?.isDudaUser && <DudaButton htmlRef={htmlRef} />}
                <SaveButton saveType="doc" htmlRef={htmlRef} />
                <SaveButton saveType="pdf" htmlRef={htmlRef} />
            </div>
        </div>
    );
}

export function HeadlessDocPreview({
    htmlRef,
    disableFreeTextEdit,
}: {
    htmlRef: React.RefObject<HTMLDivElement>;
    disableFreeTextEdit?: boolean;
}) {
    return (
        <div className="hidden">
            <div ref={htmlRef}>
                <PreviewContractWrapper disableFreeTextEdit={disableFreeTextEdit} />
            </div>
        </div>
    );
}

export type SaveType = "pdf" | "doc";

export function ShopifyButton(props: { htmlRef: React.RefObject<HTMLDivElement> }) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const agreementContext = useAgreementContext();
    const activeDraft = agreementContext?.getActiveDraft();
    if (!activeDraft?.legalUnit) {
        return null;
    }

    // const history = useHistory();
    const documentKind = activeDraft.legalUnit?.kind;

    function handleClick() {
        const documentName = documentKind === "privacyPolicy" ? "Privacy Policy" : "Terms of Use";
        const content = props.htmlRef.current?.innerHTML!;

        const handleDialog = async (yes: boolean) => {
            if (!yes) {
                return;
            }
            setLoading(true);

            try {
                const {
                    data: { url },
                } = await ShopifyApi.updatePolicy({
                    content,
                    kind: documentKind,
                    docId: activeDraft!.id,
                });

                const policyName =
                    documentKind === "privacyPolicy" ? "privacy-policy" : "terms-of-service";
                const policyUrl = `https://${url}/policies/${policyName}`;

                const action = callDialog(
                    DialogType.SUCCESS,
                    `${documentName} updated! It should up to 1 minute for you to see the results. Visit your policy page: ${policyUrl}`
                );
                dispatch(action);
            } catch (error) {
                const action = callDialog(DialogType.FAILURE, "Could not update policy");
                dispatch(action);
            } finally {
                setLoading(false);
            }
        };
        dispatch(
            callDialog(
                DialogType.CONFIRM,
                `Are you sure you want to update your ${documentName} on Shopify?`,
                handleDialog
            )
        );
    }
    return (
        <Button onClick={handleClick} variant={"outlined"} color={"skyBlue"} loading={loading}>
            <img className="w-6 cursor-pointer" src={ShopifyBag} alt="Shopify icon" />
            <p className="text-xs md:text-base md:text-sm whitespace-nowrap">Upload to Shopify</p>
        </Button>
    );
}

export function DudaButton(props: { htmlRef: React.RefObject<HTMLDivElement> }) {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const agreementContext = useAgreementContext();
    const activeDraft = agreementContext?.getActiveDraft();
    if (!activeDraft?.legalUnit) {
        return null;
    }

    // const history = useHistory();
    const documentKind = activeDraft.legalUnit?.kind;
    const documentName =
        documentKind === "privacyPolicy"
            ? "Privacy Policy"
            : documentKind === "terms-of-service"
            ? "Terms of Use"
            : "Document";
    function handleClick() {
        // const content = props.htmlRef.current?.innerHTML!;
        setOpen(true);
    }
    return (
        <>
            {open && (
                <Modal fixed>
                    <ExitButton className="z-10" onClick={() => setOpen(false)} />

                    <div className="relative mx-auto sm:w-100 md:w-264 md:p-14 p-10">
                        <div className="flex flex-col md:flex-row">
                            <div className="md:w-5/10 w-full p-4">
                                <h2 className="text-xl font-bold mb-4">Instructions</h2>
                                <p className="mb-2">
                                    Follow these three steps to add this {documentName} to your
                                    page:
                                </p>
                                <ol className="list-decimal list-inside mb-4">
                                    <li className="mb-2">
                                        Create a new page via the "Pages" button in the Left Panel
                                        of your Website Builder's General Site Editor. Give the page
                                        a meaningful name, e.g Privacy policy, Terms of use, etc.
                                    </li>
                                    <li className="mb-2">
                                        Click the Left Panel's "Widgets" button and drag the "Policy
                                        Content" widget from the widgets panel onto your page, as
                                        shown in the screenshot. Please insert only one per page.
                                    </li>
                                    <li className="mb-2">
                                        Choose your document from the dropdown.
                                    </li>
                                </ol>
                                <p className="mb-2">
                                    That's it! 🎉 Your document should now be displayed on your
                                    website.
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            <Button onClick={handleClick} variant="outlined" color="skyBlue">
                <p className="text-xs md:text-base md:text-sm whitespace-nowrap">
                    Upload to Website
                </p>
            </Button>
        </>
    );
}

export function SaveButton(props: {
    saveType: SaveType;
    htmlRef: React.RefObject<HTMLDivElement>;
}) {
    const [downloading, setDownloading] = useState(false);
    const dispatch = useDispatch();
    const agreementContext = useAgreementContext();
    const activeDraft = agreementContext?.getActiveDraft();
    const history = useHistory();

    if (!activeDraft) {
        return null;
    }

    let fileName = documentToName(activeDraft);

    function savePDF() {
        DocsApi.withSecurityHeaders()?.then(async (authHeaders) => {
            await dispatch(setShowExplanations(false));
            setDownloading(true);

            await retrieveAxios()({
                url: "/api/v1/html-to-pdf",
                method: "POST",
                responseType: "blob",
                headers: { ...authHeaders },
                data: {
                    content: props.htmlRef.current?.innerHTML,
                    id: activeDraft?.id,
                    templateId: activeDraft?.templateId,
                },
            })
                .then(async (response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName + ".pdf"); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    if (isProd()) {
                        mixpanel.track("download-pdf", { fileName: fileName });
                    }

                    await dispatch(setShowExplanations(true));
                })
                .catch((error) => {
                    if (error?.response?.status === 403) {
                        history.push("/payment-plans");
                    }
                })
                .finally(() => {
                    setDownloading(false);
                    counter--;
                }); //todo catch
        });
    }

    // todo: merge this with pdf - it looks like this due to auth
    async function saveDoc() {
        DocsApi.withSecurityHeaders()?.then(async (authHeaders) => {
            await dispatch(setShowExplanations(false));
            setDownloading(true);

            await retrieveAxios()({
                url: "/api/v1/html-to-pdf",
                method: "POST",
                responseType: "blob",
                headers: { ...authHeaders },
                data: { content: props.htmlRef.current?.innerHTML, id: activeDraft?.id },
            })
                .then(async () => {
                    const innerHtml =
                        props.htmlRef.current?.innerHTML ||
                        "<p>Error occurred. Please contact clawdia@clawdia.ai for help.</p>";

                    var preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title></title>
                    
                    <style>
	p {
		margin-top: 1rem;
	}

	h5 {
		margin-top: 0rem;
		line-height: 0;
		margin-bottom: -1rem;
	}

	ol, ul {
		margin-bottom: -1.5rem;
		list-style-type: none;
	}
	
	ol li {
		margin-top: 1rem !important;
	}
	
	ul li {
		margin-top: 1rem !important;
	}

	// scss to css from index.scss in FE:	
	#root ol ol ol {
		counter-reset: item3;
	}

	#root ol ol ol li:before {
		content: counter(item) "." counter(item2) "." counter(item3) ". ";
		counter-increment: item3;
		margin-left: 3em;
	}

	#root ol .numbered-section::before {
		content: "Section " counter(item) "." !important;
	}

	#root ol ol {
		counter-reset: item2;
	}
	
	#root ol ol li:before {
		content: counter(item) "." counter(item2) ". ";
		counter-increment: item2;
		margin-left: 1em;
	}
	
	#root ol {
		list-style-type: none;
		counter-reset: item;
	}

	#root ol li:before {
		content: counter(item) ". ";
		counter-increment: item;
	}

	#root ol::after {
		counter-set: item 0;
	}
    
	</style>
                    
                    </head><body>`;
                    var postHtml = "</body></html>";
                    var html = preHtml + innerHtml + postHtml;

                    const url =
                        "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);
                    const filenameWithExtension = fileName + ".doc";

                    const downloadLink = document.createElement("a");

                    document.body.appendChild(downloadLink);

                    downloadLink.href = url;
                    downloadLink.download = filenameWithExtension;
                    downloadLink.click();

                    document.body.removeChild(downloadLink);

                    if (isProd()) {
                        mixpanel.track("download-doc", { fileName: fileName });
                    }

                    await dispatch(setShowExplanations(true));
                })
                .catch((error) => {
                    if (error?.response?.status === 403) {
                        history.push("/payment-plans");
                    }
                })
                .finally(() => {
                    setDownloading(false);
                    counter--;
                }); //todo catch
        });
    }

    return (
        <Button
            onClick={() => {
                if (++counter > 1) {
                    counter--;
                    return;
                }

                if (props.saveType == "doc") {
                    return saveDoc();
                }

                return savePDF();
            }}
            variant={"outlined"}
            color={"skyBlue"}
            loading={downloading}
            // className='w-1/2'
        >
            {/* <img
      className="w-6 cursor-pointer"
      src={downloading ? LoadingImg : DownloadImg}
    /> */}
            <div className="hidden md:block">
                <SvgIconSave />
            </div>
            <p className="text-xs md:text-base md:text-sm whitespace-nowrap">
                {props.saveType == "doc" ? "Download Doc" : "Download PDF"}
            </p>
        </Button>
    );
}

// todo - make this more elegant - this is made to make sure we don't cause race conditions
let counter = 0;

export function PreviewContractWrapper({
    disableFreeTextEdit = false,
}: {
    disableFreeTextEdit?: boolean;
}) {
    const agreementContext = useContext(AgreementContext);
    const activeDraft = agreementContext?.getActiveDraft();
    const dispatch = useDispatch();
    const editor = useEditor();
    const currentAgreement = useAppSelector((state) => state.agreementManager.currentAgreement);

    useEffect(() => {
        // todo: find better place to load it
        if (!currentAgreement && activeDraft?.legalUnit?.docVersion) {
            autoDocService.loadContract(activeDraft.legalUnit.docVersion).then((data) => {
                dispatch(Actions.agreementManager.setCurrentAgreement(data));
                dispatch(Actions.agreementManager.setCurrentAgreement(undefined));
            });
        }
        return () => {};
    }, []);

    if (!activeDraft) {
        return null;
    }

    if (!currentAgreement) {
        return <div>Loading...</div>;
    }

    {
        /* TODO: remove when old editor is deleted */
    }

    if (activeDraft.legalUnit?.freestyleSrc && !disableFreeTextEdit) {
        let src = JSON.parse(activeDraft.legalUnit.freestyleSrc);

        return (
            <PreviewContract
                key={currentAgreement?.id}
                contract={
                    <RichEditor
                        value={src || [{ type: "paragraph", children: [{ text: "" }] }]}
                        editor={editor}
                        mode={"preview"}
                    />
                }
            />
        );
    }

    if (!currentAgreement.isMarkdown) {
        return (
            <PreviewContract
                key={currentAgreement?.id}
                contract={
                    <RichEditor
                        value={
                            currentAgreement.parsed || [
                                { type: "paragraph", children: [{ text: "" }] },
                            ]
                        }
                        editor={editor}
                        mode={"preview"}
                    />
                }
            />
        );
    }

    return (
        <PreviewContract
            key={currentAgreement?.id}
            contract={<Renderer text={currentAgreement.markdownSrc} />}
        />
    );
}
