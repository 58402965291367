import clsx from "clsx";
import { History } from "history";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router";
import { getUser, postUser, updateLogoSettings } from "../../../api-services/UseresApi";
import { PGetUserResponse, PPostUserRequest } from "../../../proto/PApi";
import {
    PLogoOptions,
    PLogoSettings,
    PUser,
    PUserStatus,
    PUserSubscriptionType,
} from "../../../proto/PUser";
import { callDialog, setDBUser } from "../../../store/mainActions";
import { DialogType, MainStore } from "../../../store/mainTypes";
import { Container } from "../../0_common";
import { isProd } from "../../utils/EnvResolver";
import { formatDate, getBase64Image } from "../../utils/UtilFunctions";
import BlueLogo from "../../../img//blue-logo.svg";
import DeleteLogo from "../../../img/icons/delete.png";
import { ReferralProgram } from "./ReferralProgram";
import { CountryDropDown, CountryOption, COUNTRY_OPTIONS } from "components/account/OnBoarding";
import { usePlanState } from "hooks/usePlanState";
import { GenericProgress } from "components/life-cycle/ProgressBar";

export default function UserSettings() {
    return (
        <div className="w-full h-full">
            <div className="w-full bg-primary">
                <UserSettingsHeader />
            </div>
            <UserSettingsBody />
        </div>
    );
}

function UserSettingsHeader() {
    return <Container className="flex items-start md:h-20 h-24"></Container>;
}

function UserSettingsBody() {
    let drafts = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.drafts);

    return (
        <Container className="pb-8">
            <div className="w-full bg-white shadow-card bg-card backdrop-blur-md -mt-20 rounded-lg h-full">
                <div className="flex flex-col md:flex-row py-4 md:py-16 divide-x divide-solid divide-lightSkyBlue h-full">
                    <div className="md:w-[30%] flex-shrink-0">
                        <SettingsSections />
                    </div>
                    <div className="w-full">
                        <SettingsContent />
                    </div>
                </div>
            </div>
        </Container>
    );
}

const sections = [
    { text: "Profile Settings", path: "account", component: <ProfileSettings /> },
    { text: "Logo Setting", path: "logo", component: <LogoSettings /> },
    { text: "Referral Program", path: "referral", component: <ReferralProgram /> },
    { text: "Billing Information", path: "billing", component: <BillingInformation /> },
    { text: "Usage information", path: "usage", component: <UsageInformation /> },
    // { text: "Designed Templates", path: "templates", component: <TemplatesPage /> },
];

function getActive(history: History) {
    const currentPath = history.location.pathname.split("/").pop();
    const activeSection = sections.filter((s) => s.path === currentPath);
    const active = activeSection[0] || sections[0];

    return active;
}

function SettingsSections() {
    const history = useHistory();
    const activePath = getActive(history).path;
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const hasPaid = dbUser?.paymentTime && dbUser?.paymentTime.getSeconds() > 0;

    const isFreeTier = dbUser?.tier === "free" && dbUser?.userStatus !== PUserStatus.POST_PAYMENT;
    const relevantSections = sections
        .filter((s) => hasPaid || (s.path !== "referral" && s.path !== "legal-team"))
        .filter((s) => isFreeTier || s.path !== "usage");

    return (
        <div className="flex text-center w-full flex-col md:gap-6 lg:px-14 md:px-2 px-4">
            {relevantSections.map((section) => (
                <div
                    key={section.path}
                    onClick={() => {
                        history.push(`/settings/${section.path}`);
                    }}
                    className={`flex w-full break-words flex-grow-0 md:text-lg text-base font-medium md:px-8 px-2 py-4 text-center md:text-left cursor-pointer
            hover:text-darkIndigo
            ${
                activePath === section.path
                    ? "md:bg-iceBlue rounded-[7.5rem] text-darkIndigo "
                    : "text-primaryLight"
            }
            `}
                >
                    {section.text}
                </div>
            ))}
        </div>
    );
}

function SettingsContent() {
    const history = useHistory();
    const activeSection = getActive(history);

    return (
        <div className="w-full h-full md:px-16 md:mt-0 mt-8 px-10">
            <Switch>
                {sections.map((section) => (
                    <Route key={section.path} path={`/settings/${section.path}`}>
                        <p className="text-darkIndigo font-medium text-2xl mb-8">{section.text}</p>
                        {section.component}
                    </Route>
                ))}

                <Route path="/settings">
                    <Redirect to={`/settings/${activeSection.path}`} />
                </Route>
            </Switch>
        </div>
    );
}

function ProfileSettings() {
    const dispatch = useDispatch();
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const [editMode, setEditMode] = useState(false);

    const [fullName, setFullName] = useState<string>(dbUser?.fullName || "");
    const [title, setTitle] = useState<string>(dbUser?.title || "");
    const [companyFullName, setCompanyFullName] = useState<string>(dbUser?.companyFullName || "");
    const [address, setAddress] = useState<string>(dbUser?.address || "");
    const [usState, setUsState] = useState<string>(dbUser?.usState || "");
    const [country, setCountry] = useState<string>(dbUser?.country || "");

    const selectedCountry = COUNTRY_OPTIONS.find((c) => c.value === country) || COUNTRY_OPTIONS[0];

    useEffect(() => {
        getUser((resp: PGetUserResponse) => {
            dispatch(setDBUser(resp.user));
        });
        return () => {
            setFullName(dbUser?.fullName || "");
            setTitle(dbUser?.title || "");
            setCompanyFullName(dbUser?.companyFullName || "");
        };
    }, []);

    return (
        <div className="flex flex-col">
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    if (!editMode) {
                        setEditMode(true);
                    }

                    if (editMode) {
                        let user: PUser | undefined = dbUser;
                        if (user) {
                            let req: PPostUserRequest = {
                                user: {
                                    ...user,
                                    companyFullName: companyFullName,
                                    fullName: fullName,
                                    title: title,
                                    address: address,
                                    usState: usState,
                                    country: country,
                                },
                            };
                            postUser(req, () => {
                                setEditMode(false);
                                dispatch(setDBUser(req.user));
                            });
                        }
                    }
                }}
            >
                <div className="flex flex-col justify-start gap-4 md:w-2/3">
                    <p className="mb-8 text-base">
                        <b>Please note!</b>
                        <br />
                        Changes made now will only affect newly created documents
                    </p>
                    <KeyVal k="E-mail" val={dbUser?.email} />
                    <KeyVal k="Account Id" val={dbUser?.accountName} />
                    <KeyVal
                        k="Company Name"
                        val={companyFullName}
                        editMode={editMode}
                        setter={setCompanyFullName}
                    />
                    <KeyVal k="Full name" val={fullName} editMode={editMode} setter={setFullName} />
                    <KeyVal k="Title" val={title} editMode={editMode} setter={setTitle} />

                    {/* Legacy where we had address as two fields - will be removed after migration */}
                    <KeyVal
                        k="Address"
                        val={calcAddress(address, usState)}
                        editMode={editMode}
                        setter={(val: string) => {
                            setAddress(val);
                            setUsState("");
                        }}
                    />

                    <span className="grid md:grid-cols-[1fr_2fr] grid-cols-1 gap-1">
                        <p className={"text-base mr-4 min-w-max "}>Country: </p>
                        <span className={editMode ? "text-primary" : "text-accent"}>
                            <CountryDropDown
                                disable={!editMode}
                                options={COUNTRY_OPTIONS}
                                selected={selectedCountry}
                                setSelected={(val: CountryOption) => {
                                    setCountry(val.value);
                                }}
                            />
                        </span>
                    </span>

                    <div className="grid grid-cols-[1fr_2fr]">
                        <div />
                        <div className="flex flex-row gap-4">
                            <button
                                type="button"
                                className={clsx(
                                    "px-4 py-2 rounded-lg font-medium text-lg mt-4",
                                    { "bg-primary border-2 border-primary text-white": !editMode },
                                    { "bg-white border-2 border-primary text-primary": editMode }
                                )}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setEditMode(!editMode);
                                }}
                            >
                                {editMode ? "Cancel" : "Edit Profile"}
                            </button>
                            {editMode && (
                                <button
                                    type="submit"
                                    className="bg-primary px-4 py-2 border-2 border-primary rounded-xl text-white font-medium text-lg w-24 mt-4"
                                >
                                    Save
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

function KeyVal(props: { k: string; val?: string; editMode?: boolean; setter?: Function }) {
    return (
        <span className="grid md:grid-cols-[1fr_2fr] grid-cols-1 gap-1 items-center">
            <p className={"text-base mr-4 min-w-max "}>{props.k}: </p>
            <input
                required={true}
                onChange={(e) => {
                    if (props.setter) {
                        props.setter(e.target.value);
                    }
                }}
                disabled={!props.editMode}
                type="string"
                className={
                    "w-full text-base px-2 md:px-4 py-2 border rounded-lg " +
                    (props.editMode && "text-primary")
                }
                value={props.val}
            />
        </span>
    );
}

function calcAddress(address: string, usState: string) {
    if (usState) {
        return `${address}, ${usState}`;
    }

    return address;
}

function BillingInformation() {
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);

    return (
        <div className="flex flex-col md:w-2/3 gap-4">
            <KeyVal k="Plan" val={getPlanName(dbUser)} />
            <KeyVal
                k="Plan renewal period"
                val={subscriptionTypeToText(dbUser?.subscriptionType)}
            />
            <KeyVal k="Plan start date" val={formatDate(dbUser?.paymentTime)} />
            <br />
            <p className="text-sm break-words" style={{ maxWidth: "fit-content" }}>
                To cancel your subscription, please remove the app via the app store
            </p>
        </div>
    );
}

function UsageInformation() {
    const history = useHistory();
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const { numDocumentsExceeded, plan, planState } = usePlanState();
    const numDocumentsAllowed = plan?.numDocuments || 0;
    let numDocumentsUsed = planState?.documentsUsed?.length || 0;
    if (numDocumentsUsed > numDocumentsAllowed) {
        numDocumentsUsed = numDocumentsAllowed;
    }

    const isQuotaExceeded = numDocumentsExceeded && dbUser?.userStatus !== PUserStatus.POST_PAYMENT;
    return (
        <div className="flex flex-col md:w-2/3 gap-4">
            <KeyVal k="Plan" val={plan?.tier} />
            <span className="grid md:grid-cols-[1fr_2fr] grid-cols-1 gap-1 items-center">
                <p className={"text-base mr-4 min-w-max "}>Documents used: </p>
                <GenericProgress
                    label="value"
                    endState="negative"
                    value={numDocumentsUsed}
                    limit={numDocumentsAllowed}
                />
            </span>
            {isQuotaExceeded && (
                <>
                    <p className="text-sm break-words" style={{ maxWidth: "fit-content" }}>
                        You have reached the limit of your plan. Please upgrade your plan to
                        continue using Clawdia.ai.
                    </p>
                    <button
                        onClick={() => history.push("/payment-plans")}
                        className="bg-primary px-4 py-2 border-2 border-primary rounded-xl text-white font-medium text-md w-40 mt-4"
                    >
                        Click to Upgrade
                    </button>
                </>
            )}
        </div>
    );
}

function getPlanName(dbUser?: PUser) {
    if (dbUser?.userStatus === PUserStatus.POST_PAYMENT) {
        return "Premium";
    }

    return "Free";
}

function subscriptionTypeToText(subscriptionType: PUserSubscriptionType | undefined) {
    switch (subscriptionType) {
        case PUserSubscriptionType.MONTHLY_SUBSCRIPTION:
            return "Monthly";
        case PUserSubscriptionType.YEARLY_SUBSCRIPTION:
            return "Yearly";
        case PUserSubscriptionType.LIFETIME_SUBSCRIPTION:
            return "Lifetime";
        default:
            return "";
    }
}

function LogoSettings() {
    const dbUser = useSelector((state: { mainReducer: MainStore }) => state.mainReducer.dbUser);
    const [option, setOption] = useState<PLogoOptions>(
        dbUser?.logoSettings?.logoOption || PLogoOptions.PLogoOptions_EVERY_PAGE
    );
    const [imgBase64, setImgBase64] = useState<string>(dbUser?.logoSettings?.imgBase64 || "");
    const [changed, setChanged] = useState<boolean>();
    const dispatch = useDispatch();

    function saveLogoSettings() {
        if (!changed) {
            return;
        }

        if (isProd()) {
            mixpanel.track("logo-settings-changed");
        }

        const logoSettings: PLogoSettings = { logoOption: option, imgBase64: imgBase64, width: 60 };
        updateLogoSettings(
            logoSettings,
            () => {
                setChanged(false);

                getUser((resp: PGetUserResponse) => {
                    dispatch(setDBUser(resp.user));
                });
            },
            () => {
                dispatch(
                    callDialog(
                        DialogType.FAILURE,
                        "Error saving changes. Try again. If problem persists, reach our support at clawdia@clawdia.ai."
                    )
                );
            }
        );
    }

    return (
        <div className="flex flex-col">
            {option !== PLogoOptions.PLogoOptions_DISABLE_LOGO && (
                <LogoUpload
                    imgBase64={imgBase64}
                    setImgBase64={setImgBase64}
                    setChanged={setChanged}
                />
            )}

            <div className="flex flex-col gap-2">
                <p className="mt-8 text-base font-medium">Where to place logo?</p>
                <LogoOption
                    id="every_page"
                    label="Every page"
                    value={PLogoOptions.PLogoOptions_EVERY_PAGE}
                    activeOption={option}
                    setOption={setOption}
                    setChanged={setChanged}
                />
                {/* <LogoOption id="first_page" label='First page only' value={PLogoOptions.PLogoOptions_FIRST_PAGE} activeOption={option} setOption={setOption} setChanged={setChanged} /> */}
                <LogoOption
                    id="disable"
                    label="No logo (disable)"
                    value={PLogoOptions.PLogoOptions_DISABLE_LOGO}
                    activeOption={option}
                    setOption={setOption}
                    setChanged={setChanged}
                />
            </div>

            <button
                type="button"
                className={clsx(
                    "flex-grow-0 w-64 mt-12 px-4 py-2 rounded-lg text-lg font-medium",
                    { "bg-lightPurple text-primary text-opacity-50 cursor-not-allowed": !changed },
                    { "bg-primary text-white cursor-pointer": changed }
                )}
                onClick={saveLogoSettings}
            >
                {changed ? "Save Logo Changes" : "Saved"}
            </button>
        </div>
    );
}

function LogoOption(props: {
    id: string;
    label: string;
    value: PLogoOptions;
    activeOption: PLogoOptions;
    setOption: Function;
    setChanged: Function;
}) {
    return (
        <div className="flex items-center gap-2 text-base">
            <input
                onChange={() => {
                    props.setOption(props.value);
                    props.setChanged(true);
                }}
                name="logoOptions"
                type="radio"
                id={props.id}
                value={props.value}
                defaultChecked={props.value === props.activeOption}
            />
            <label htmlFor={props.id}>{props.label}</label>
        </div>
    );
}

function LogoUpload(props: { imgBase64: string; setImgBase64: Function; setChanged: Function }) {
    const [img, setImg] = useState<string>(props.imgBase64);
    const imgRef = React.createRef<HTMLImageElement>();

    const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            if (!file) return;

            const url = URL.createObjectURL(file);

            setImg(url);
            getBase64Image(imgRef.current, (base64Res: string) => {
                props.setImgBase64(base64Res);
                props.setChanged(true);
            });
        }
    };

    return (
        <div>
            <p className="text-xl mt-4 font-medium">Logo Upload</p>
            <div className="flex justify-start items-center w-full mt-4">
                <label
                    htmlFor="dropzone-file"
                    className="flex flex-col justify-center items-center w-72 bg-gray-100 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-200 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                    <div className="bg-white px-8 pt-4 w-auto inline-flex group mt-2 rounded-xl">
                        <img
                            ref={imgRef}
                            className="my-4 h-8"
                            src={img ? img : BlueLogo}
                            alt="img"
                        />
                        {img && (
                            <img
                                className="w-6 h-6 group-hover:flex hidden"
                                src={DeleteLogo}
                                onClick={() => {
                                    props.setImgBase64("");
                                    setImg("");
                                    props.setChanged(true);
                                }}
                            />
                        )}
                    </div>

                    <div className="flex flex-col justify-center items-center pt-5 pb-6">
                        <svg
                            aria-hidden="true"
                            className="mb-2 w-7 h-7 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">Click to upload</span> or drag and drop
                        </p>
                        <p className="mb-1 text-xs text-gray-500 dark:text-gray-400">
                            SVG, PNG, JPG or GIF
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                            (For best results use 125x25px)
                        </p>
                    </div>
                    <input
                        accept="image/*"
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        onChange={handleChangeImage}
                    />
                </label>
            </div>
        </div>
    );
}
